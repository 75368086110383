<template>
    <section class="view" :class="{'is-content': (pageType === 'content_page' || pageType === 'faq')}">

        <breadcrumb v-if="pageType === 'content_page' || pageType === 'faq'" :pageName="pageName" :nav="navigation" />
        <contentpage v-if="pageType === 'content_page' || pageType === 'faq'" :title="pageName" :content="content"/>
        <filepage v-if="pageType === 'files'" :content="content" :title="pageName"/>

        <div class="spinner" v-if="loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    </section>
</template>

<script>
    import Contentpage from "./Contentpage"
    import Filepage from "./Filepage"
    import Breadcrumb from "../components/_partials/Breadcrumb"
    import { mapGetters } from "vuex";

    export default {
        name: 'PageSwitcher',
        data(){return {}},
        components:{
            Contentpage,
            Filepage,
            Breadcrumb,
        },
        computed:{
            ...mapGetters({
                pageName: 'content/getPageName',
                navigation: 'content/getNav',
                pageType: 'content/getPageType',
                content: 'content/getContent',
                loading: 'content/getLoading'
            })
        },
        watch: {
            '$route.path': function() {

                this.$store.commit('content/CHANGE_CONTENT', null);
                this.$store.dispatch('content/initContent', this.$route.path);
            },
        },
        mounted(){
            if(!this.navigation.length)
                this.$store.dispatch('content/initData');

            this.$store.dispatch('content/initContent', this.$route.path);
        }
    }
</script>
