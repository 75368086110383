import router from '../router'

const RedirectService = {
    /**
     * Redirect to Login page and keep redirect path.
     **/
    login: function() {
        const that = this;

        if(!that.redirecting) {
            const redirectRoute = router.currentRoute.path;
            router.push({ name: 'Login', query: { redirect: redirectRoute } });
            that.redirecting = true;
        }
    },
    /**
     * Debounce helper for redirection
     */
    redirecting: false
}

export { RedirectService }
