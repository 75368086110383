const getNav = state => state.navigation;
const getPageName = state => state.pageName;
const getContent = state => state.currentContent;
const getPageType = state => state.pagetype;
const getLoading = state => state.loading;
const get404 = state => state.show404;
const getSelectedFiles = state => state.selectedFiles;
const getParticipants = state => state.participants;
const getnavLevelOne = state => state.navLevelOne;
const getnavLevelTwo = state => state.navLevelTwo;

export default {
    getNav,
    getPageName,
    getContent,
    getPageType,
    getLoading,
    get404,
    getSelectedFiles,
    getnavLevelOne,
    getnavLevelTwo,
    getParticipants
};
