<template>
    <div :class="{'rte':!content.field_accordion, 'toggles':content.field_accordion}">

        <template v-if="!content.field_accordion">
            <h2 v-if="content.field_headline">{{content.field_headline}}</h2>
            <div v-html="content.field_text"></div>
        </template>
        <template v-else>
            <toggle-box :content="content.field_text" :headline="content.field_headline" :open="content.field_default_open" :columns="content.field_show_columns"/>
        </template>
    </div>
</template>
<script>
    import ToggleBox from '../partials/ToggleBox';

    export default {
        name: 'rte',
        props: ['content'],
        components:{ ToggleBox },
        data(){
            return {
            }
        }
    }
</script>
