<template>
    <form id="new" :class="{'is-invalid':showErrors}" @submit="checkForm">
        <span class="shape"></span>
        <div class="form-inner success-message" v-if="newSuccess">
            <h1><b>Glückwunsch</b></h1>
            <p>Sie können sich nun mit Ihrem neuen Passwort einloggen!</p>
            <div class="row">
                <div class="col50">
                    <router-link to="/login" class="link-switch log-in">Zum Login <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
                </div>
            </div>
        </div>
        <div class="row row-single" v-if="!newSuccess">
            <h1><b>Neues Passwort</b></h1>
            <p>Hier können Sie Ihr neues Passwort festlegen.</p>
            <password ref="password" v-model="password" placeholder="Passwort*" name="password" @isValid="changePasswordValidation"/>
            <check-list ref="privacy" name="privacycheck" content='<p>Durch die Angabe meiner freiwillig bereitgestellten Kontaktdaten, stimme ich einer Verarbeitung durch das „IG Realisierungszentrum 2. Stammstrecke München“, zum Zwecke der Information an Projektbeteiligte und Geschäftspartner, ausdrücklich und bis auf Widerruf zu. <a href="/datenschutz" target="_blank">Mehr dazu</a>.</p>' @isValid="changePrivacyValidation"/>
            <button class="btn" type="submit">Passwort speichern</button>
            <div class="spinner" v-if="authProgress"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters } from "vuex";
    import Password from './fields/Password'
    import CheckList from './fields/CheckList'

    export default {
        name: "NewPassword",
        data() {
            return {
                password: "",
                passwordValid: false,
                showErrors:false,
                privacy: false
            };
        },
        components: {Password, CheckList},
        computed: {
            ...mapGetters({
                newSuccess: 'auth/newSuccess',
                authProgress: 'auth/authProgress'
            })
        },
        methods: {
            changePasswordValidation(value){
                this.passwordValid  = value;

                this.updateErrorClass()
            },
            changePrivacyValidation(value){
                this.privacy  = value;

                this.updateErrorClass();
            },
            updateErrorClass(){
                if(this.passwordValid && this.privacy)
                    this.showErrors = false;
            },
            checkForm(e) {
                this.$refs.password.validate(this.password);
                this.$refs.privacy.validate();

                if(this.passwordValid && this.privacy) {
                    this.performPasswordChange();
                }else{
                    this.showErrors = true;
                }

                e.preventDefault();
            },
            performPasswordChange() {
                let payload = {
                    password: this.password,
                    key: this.$route.query.key
                };

                this.password = "";
                this.$refs.password.resetClass();
                this.$store.dispatch('auth/prePasswordNew', payload);
            }
        },
        mounted(){
            if(!this.$route.query || !this.$route.query.key)
                this.$router.push({name: 'Reset Password'})
        }
    };
</script>
