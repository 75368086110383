<template>
    <form id="register" :class="{'is-invalid':showErrors}" @submit="checkForm">
        <span class="shape"></span>
        <div class="form-inner success-message" v-if="registerSuccess">
            <h1><b>Vielen Dank</b> Ihre Registrierung war erfolgreich!</h1>
            <p>Wir benachrichtigen Sie sobald Ihr Benutzerkonto freigeschalten wurde.</p>
            <div class="row">
                <div class="col50">
                    <router-link to="/login" class="link-switch log-in">Zum Login <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
                </div>
            </div>
        </div>
        <div class="form-inner" v-if="!registerSuccess">
            <h1><b>Willkommen</b> im Digitalen Projekthandbuch</h1>
            <p>Registrieren Sie Sich mit Ihren persönlichen Daten um Zugriff auf das Digitale Projekthandbuch zu bekommen.</p>
            <div class="row">
                <div class="col50">
                    <strong>Pflichtfelder*</strong>
                    <text-input ref="firstname" empty="Bitte geben Sie IhrenVornamen ein!" v-model="firstname" placeholder="Vorname*" name="firstname" :required="true" @isValid="changeFirstnameValidation"/>
                    <text-input ref="name" empty="Bitte geben Sie Ihren Nachnamen ein!" v-model="name" placeholder="Nachname*" name="name" :required="true" @isValid="changeNameValidation"/>
                    <email ref="email" v-model="email" placeholder="E-Mail Adresse*" name="email" @isValid="changeEmailValidation"/>
                    <password ref="password" v-model="password" placeholder="Passwort*" name="password" @isValid="changePasswordValidation"/>
                </div>
                <div class="col50">
                    <strong>Weitere Angaben</strong>
                    <text-input ref="phone" name="phone" v-model="phone" placeholder="Telefon"/>
                    <text-input ref="mobile" name="mobile" v-model="mobile" placeholder="Mobil"/>
                </div>
            </div>
            <div class="row">
                <check-list ref="privacy" name="privacycheck" content='<p>Durch die Angabe meiner freiwillig bereitgestellten Kontaktdaten, stimme ich einer Verarbeitung durch das „IG Realisierungszentrum 2. Stammstrecke München“, zum Zwecke der Information an Projektbeteiligte und Geschäftspartner, ausdrücklich und bis auf Widerruf zu. <a href="/datenschutz" target="_blank">Mehr dazu</a>.</p>' @isValid="changePrivacyValidation"/>
            </div>
            <button class="btn" type="submit">Jetzt registrieren</button>
            <router-link to="/login" class="link-switch log-in">Zum Login <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
            <div class="spinner" v-if="authProgress"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        </div>
    </form>
</template>


<script>
    import { mapGetters } from "vuex";
    import Password from './fields/Password'
    import Email from './fields/Email'
    import TextInput from './fields/Input'
    import CheckList from './fields/CheckList'

    export default {
        name: "Register",
        data() {
            return {
                firstname: "",
                firstnameValid: false,
                name: "",
                nameValid: false,
                email: "",
                emailValid: false,
                password: "",
                passwordValid: false,
                showErrors:false,
                phone: "",
                mobile: "",
                address: "",
                image: "",
                privacy: false
            };
        },
        components: { Password, Email, TextInput, CheckList},
        computed: {
            ...mapGetters({
                registerSuccess: 'auth/registerSuccess',
                authProgress: 'auth/authProgress'
            })
        },
        methods: {
            changeFirstnameValidation(value){
                this.firstnameValid  = value;

                this.updateErrorClass();
            },
            changeNameValidation(value){
                this.nameValid  = value;

                this.updateErrorClass();
            },
            changePrivacyValidation(value){
                this.privacy  = value;

                this.updateErrorClass();
            },
            changeEmailValidation(value){
                this.emailValid  = value;

                this.updateErrorClass();
            },
            changePasswordValidation(value){
                this.passwordValid  = value;

                this.updateErrorClass();
            },
            updateErrorClass(){
                if(this.passwordValid && this.emailValid && this.nameValid && this.firstnameValid && this.privacy)
                    this.showErrors = false;
            },
            checkForm(e) {
                this.$refs.firstname.validate(this.firstname);
                this.$refs.name.validate(this.name);
                this.$refs.email.validate(this.email);
                this.$refs.password.validate(this.password);
                this.$refs.privacy.validate();

                if(this.passwordValid && this.emailValid && this.nameValid && this.firstnameValid && this.privacy) {
                    this.performRegister();
                }else{
                    this.showErrors = true;
                }

                e.preventDefault();
            },
            performRegister() {
                let payload = {
                    firstname: this.firstname,
                    lastname: this.name,
                    email: this.email,
                    password: this.password
                };

                if(this.phone.length) payload["phone"] = this.phone;
                if(this.mobile.length) payload["mobile"] = this.mobile;

                this.password = "";
                this.$refs.password.resetClass();
                this.$store.dispatch('auth/preRegister', payload);
            }
        }
    };
</script>
