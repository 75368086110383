const CLEAR_SELECTED = (state) => {
    console.log('%cMutation'+'%c – clear selected files!', 'color: mediumpurple', 'color: white');

    state.selectedFiles = [];
};

export default {
    CLEAR_SELECTED
};


