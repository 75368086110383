<template>
    <section class="view notification-view">
        <div class="notification-overview">
            <h1>Aktuelle Meldungen</h1>
            <ul>
                <notification v-for="(item, index) in notifications" :key="index" :item="item"/>
            </ul>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import Notification from '../components/notifications/Notification'

    export default {
        name: 'NotificationOverview',
        computed:{
            ...mapGetters({
                navigation: 'content/getNav',
                notifications: 'notifications/getAll',
            }),
        },
        components: {
            Notification
        },
        data(){
            return {
            }
        },
        mounted(){
            if(!this.navigation.length)
                this.$store.dispatch('content/initData');
        }
    }
</script>
