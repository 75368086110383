<template>
    <v-touch class="hotspots image-preview" :data-zoom="imageZoom" @click.stop @panstart="startDrag" @panend="endDrag" @panmove="doDrag">
        <img class="image-size" :src="content.file[0].url" style="opacity:0;visibility:hidden;">
        <figure :style="style" :class="{'is-animating':animate}">
            <img :src="content.file[0].url"/>
            <template v-for="(item, index) in content.paragraph">
                <span class="hotspot" :style="'top:'+item.field_hostpot_top+'%;left:'+item.field_hotspot_left+'%;'" @click="active = (active === index)? null : index" :class="{'is-active':active === index}" :key="index">
                <span class="hotspot-window"
                      :class="[leftClass(item.field_hotspot_left),bottomClass(item.field_hostpot_top)]"
                      :style="fixOffset(item.field_hotspot_left)"
                      @click.stop>

                    <template v-for="(file, index) in item.file">
                        <img v-if="isImage(file.url)" :src="file.url" alt="Hotspot Image" :key="index"/>
                    </template>
                    <strong v-if="item.field_headline">{{ item.field_headline }}</strong>
                    <span v-html="item.field_text" v-if="item.field_text"></span>
                    <a :href="item.field_link[0].alias" class="link" v-if="item.field_link && item.field_link.length" target="_blank">{{ item.field_link[0].title }}<svg class="icon"><use xlink:href="#angle-right"></use></svg></a>

                    <template v-for="(file, index) in item.file">
                        <a v-if="!isImage(file.url)" :href="file.url" :key="index" class="btn" target="_blank"><svg class="icon"><use xlink:href="#download"></use></svg> Download</a>
                    </template>
                </span>
            </span>
            </template>
        </figure>
        <span class="controls">
            <svg class="icon" @click="zoomOut" :disabled="!imageZoom"><use xlink:href="#zoom-out" title="Zoom Out"></use></svg>
            <svg class="icon" @click="zoomIn" :disabled="imageZoom === 4"><use xlink:href="#zoom" title="Zoom In"></use></svg>
        </span>
    </v-touch>
</template>
<script>
    import Vue from "vue";
    import VueTouch from 'vue-touch';

    Vue.use(VueTouch, {name: 'v-touch'});

    export default {
        name: 'hotspots',
        props: ['content'],
        data(){
            return {
                active: null,
                imageZoom:0,
                boundsX:0,
                boundsY:0,
                left:0,
                top:0,
                startLeft:0,
                startTop:0,
                style:"",
                animate: true
            }
        },
        methods: {
            isImage(path){
                return (/\.(gif|jpg|jpeg|tiff|png)$/i).test(path);
            },
            leftClass(val){
                let vw = window.innerWidth;

                if(vw < 620) {
                    return "is-fixed";
                }else{
                    return (val < 50) ? "is-left" : "";
                }
            },
            bottomClass(val){
                return (val > 50) ? "is-bottom" : "";
            },
            fixOffset(val){
                let vw = window.innerWidth,
                    offset = val * (vw-40) / 100;

                if(vw < 620)
                    return "left:-"+(offset-30)+"px;";
            },
            zoomIn(){
                if(this.imageZoom < 4)
                    this.imageZoom++;
            },
            zoomOut(){
                if(this.imageZoom > 0) {
                    this.adjustPos();
                }
            },
            adjustPos(){
                let subPerc = 100/this.imageZoom,
                    newLeft = (100-subPerc) * this.left / 100,
                    newTop = (100-subPerc) * this.top / 100;

                if(subPerc < 100){
                    this.left = newLeft;
                    this.top = newTop;
                    this.style = "left:"+this.left+"px;top:"+this.top+"px;";
                }else{
                    this.left = 0;
                    this.top = 0;
                    this.style = "";
                }
                this.imageZoom--;
            },
            startDrag(e) {
                let multiplier = 1 + (this.imageZoom * 0.5),
                    w = e.target.getBoundingClientRect().width,
                    h = e.target.getBoundingClientRect().height;

                this.animate = false;

                this.boundsX = Math.floor(((w * multiplier) - w) / 2);
                this.boundsY = Math.floor(((h * multiplier) - h) / 2);

                this.startLeft = this.left;
                this.startTop = this.top;
            },
            endDrag() {
                this.animate = true;
            },
            doDrag(e) {
                if(this.imageZoom) {

                    let newLeft = this.startLeft + e.deltaX,
                        newTop = this.startTop + e.deltaY;

                    if(newLeft < this.boundsX && newLeft > -this.boundsX) {
                        this.left = newLeft;
                    }

                    if(newTop < this.boundsY && newTop > -this.boundsY) {
                        this.top = newTop;
                    }

                    this.style = "left:"+this.left+"px;top:"+this.top+"px;";
                }
            }
        }
    }
</script>
