import 'es6-promise/auto';

// base imports
import Vue from 'vue'
import App from './App'
import router from './router.js'
import store from './store'

// other imports
import VueAxios from 'vue-axios'
import axios from 'axios'

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

String.prototype.replaceAll = function(search, replacement) {
    return this.split(search).join(replacement);
};

//directives
Vue.directive('lazybg', {
    bind: function (el, binding) {
        let vals = binding.value.split('.');

        el.style.backgroundImage = `url(/static${vals[0]}_thumb.${vals[1]})`;

        let img = new Image();
        img.onload = () => {
            el.style.backgroundImage = `url(/static${binding.value})`;
            el.style.filter = "none";
        };
        img.src = binding.value
    }
});



new Vue({
    el: '#app',
    store,
    router,
    axios,
    render: h => h(App)
});
