import Vue from 'vue'

// Global Loading Indicator
const SET = (state, indicatorArr) => {
    Vue.set(state.indicator, indicatorArr[0], indicatorArr[1]);
};

const DEL = (state, name) => {
    Vue.delete(state.indicator, name);
};

export default {
    SET,
    DEL
}
