const INIT_ALL = (state, data) => {
    console.log('%cMutation'+'%c – save notifications to state!', 'color: mediumpurple', 'color: white');

    if(data)
        state.notifications = data;

    // compare localstore with current
    let localstorage = JSON.parse(localStorage.getItem('sbss_notifications')) || "";
    let cfg = data.map(item => item.id);
    let newData = data.length > localstorage.split(',').length;

    cfg = cfg.join(',');


    if(localstorage !== cfg && newData)
        state.newNotification = data.length;
};
const CHANGE_STATUS = (state, status) => {
    console.log('%cMutation'+'%c – change notification status!', 'color: mediumpurple', 'color: white');

    state.newNotification = status;

    let data = state.notifications;
    let cfg = data.map(item => item.id);

    cfg = cfg.join(',');

    localStorage.setItem('sbss_notifications', JSON.stringify(cfg));
};

// EVENTS
const INIT_EVENTS = (state, data) => {
    console.log('%cMutation'+'%c – save events to state!', 'color: mediumpurple', 'color: white');

    console.log(data);

    state.events = data;
};


export default {
    INIT_ALL,
    CHANGE_STATUS,
    INIT_EVENTS
}
