<template>
    <svg class="icon"><use :xlink:href="getID(name)"></use></svg>
</template>

<script>
    export default {
        name: 'icon',
        props: ['name'],
        methods: {
            getID(name)
            {
                let val = name;

                switch(name) {
                    case 'Arbeitsplatz':
                        val = 'workplace';
                        break;
                    case 'RZ':
                        val = 'rz';
                        break;
                    case 'DB Team':
                        val = 'team';
                        break;
                    case 'Technisches PM':
                        val = 'tpm';
                        break;
                    case 'Das Projekt':
                        val = 'project';
                        break;
                    case 'Arbeitsgebiete':
                        val = 'sections';
                        break;
                    case 'Infoportal':
                        val = 'infoportal';
                        break;
                    case 'Tools':
                        val = 'tools';
                        break;
                    case 'Document':
                        val = 'document';
                        break;
                    case 'Info':
                        val = 'info';
                        break;
                    case 'Warning':
                        val = 'warn';
                        break;
                }

                return '#'+val;
            }
        }
    };
</script>

