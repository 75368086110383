<template>
    <section class="view dashboard">
        <h1><b>Willkommen</b> im Digitalen Projekthandbuch</h1>
        <div class="tiles">
            <router-link v-for="(item, index) in navigation" :key="index" :to="item.alias">
                <icon :name="item.icon_id"/>
                <span>{{ item.title }}</span>
            </router-link>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import Icon from '../components/_partials/Icon'

    export default {
        name: 'Dashboard',
        components: {
            Icon
        },
        computed:{
            ...mapGetters({
                navigation: 'content/getNav'
            }),
        },
        data(){
            return {
            }
        },
        mounted(){
            if(!this.navigation.length)
                this.$store.dispatch('content/initData');
        }
    }
</script>

