import getters from './getters';
import mutations from './mutations';


// initial state
const state = {
    indicator:{}
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
