import axios from 'axios';

const setBaseUrl = function() {
    // Bestimmen der Base URL basierend auf der Umgebung
    let baseURL = '';

    if (process.env.NODE_ENV === 'development') {
        baseURL = process.env.VUE_APP_API_ENDPOINT_DEV;
    } else if (process.env.NODE_ENV === 'staging') {
        baseURL = process.env.VUE_APP_API_ENDPOINT_STAGING;
    } else if (process.env.NODE_ENV === 'production') {
        baseURL = process.env.VUE_APP_API_ENDPOINT_PRODUCTION;
    } else {
        console.error('Unknown environment:', process.env.VUE_APP_API_ENDPOINT_PRODUCTION);
    }

    axiosBasic.baseUrl = baseURL;
    axiosForm.baseUrl = baseURL;
}

// Erstellt axios Instanzen mit der dynamischen baseURL
const axiosBasic = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With':'webapp'
    },
});

const axiosForm = axios.create({
    headers: {
        'Accept': 'application/json, text/javascript, */*; q=0.01',
        'Content-Type': 'multipart/form-data',
        'X-Requested-With':'webapp'
    }
});

export { axiosBasic, axiosForm, setBaseUrl };
