<template>
    <div class="files">
        <h2>{{ content.field_headline }}</h2>
        <ul>
            <file v-for="(item, index) in content.paragraph" :item="item" :key="index"/>
        </ul>
    </div>
</template>
<script>
    import File from '../../_partials/File'

    export default {
        name: 'files',
        props: ['content'],
        components:{
            File
        },
        data(){
            return {
            }
        }
    }
</script>
