<template>
    <li class="notifications" :class="{open:showNotification}">
        <span class="btn-notifications" @click="showWindow" :class="{'is-updated':newNotification}" :data-count="newNotification"><svg class="icon"><use xlink:href="#bell"></use></svg></span>

        <ul class="notification-window" v-if="showNotification" v-click-outside="onClickOutside">
            <li :class="{'is-active': !nav}">
                <span @click="nav=0">Meldungen</span>
                <ul>
                    <template v-if="notifications.length">
                        <notification  v-for="(item, index) in notifications.slice(0,5)" :key="index" :item="item" :class="'is-'+item.field_priority"/>
                    </template>
                    <li class="empty" v-if="!notifications.length">Keine neuen Meldungen!</li>
                    <li class="all" @click="closeWindow" v-if="notifications.length"><router-link to="/meldungen" >Alle Meldungen <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link></li>
                </ul>
            </li>
            <li :class="{'is-active': nav}">
                <span @click="nav=1">Events</span>
                <ul>
                    <event v-for="(item, index) in filteredEvents.slice(0,5)" :key="index" :item="item"/>
                    <li class="empty" v-if="!filteredEvents.length">Keine neuen Events!</li>
                    <li class="all" @click="closeWindow" v-if="filteredEvents.length"><router-link to="/events" >Alle Events <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link></li>
                </ul>
            </li>
        </ul>
    </li>
</template>

<script>
    import { mapGetters } from 'vuex';
    import vClickOutside from 'v-click-outside'

    import Notification from './Notification'
    import Event from './Event'

    export default {
        directives: {
            clickOutside: vClickOutside.directive
        },
        computed: {
            ...mapGetters({
                notifications: 'notifications/getAll',
                events: 'notifications/getEvents',
                newNotification: 'notifications/getNew',
                newEvent: 'notifications/getNewEvent',
            }),
            filteredEvents: function () {
                return this.events.filter(function (item) {
                    return item.field_start_date
                })
            }
        },

        data() {
            return {
                showNotification: false,
                nav: 0
            }
        },
        components: {
            Notification,
            Event
        },
        methods: {
            onClickOutside () {
                this.closeWindow();
            },
            // deleteNotifications()
            // {
            //     this.$store.commit('notifications/CHANGE_STATUS', false);
            //     this.$store.commit('notifications/DELETE_ALL');
            // },
            showWindow(){
                this.showNotification = true;
            },
            closeWindow(){
                this.showNotification = false;
                this.$store.commit('notifications/CHANGE_STATUS', false);
            }
        },
        mounted() {
            let that = this;

            setTimeout(function(){
                if(that.newNotification) {
                    that.showNotification = true;
                }
            }, 2000);
        }
    };
</script>
