<template>
    <div class="content">
        <component v-for="(item, index) in schema" :key="index" :is="item.type" :content="item"/>
    </div>
</template>

<script>
    import rte from './modules/RTE'
    import hotspots from './modules/Hotspots'
    import team from './modules/Team'
    import teaser from './modules/Teaser'
    import boxes from './modules/Boxes'
    import vid from './modules/Video'
    import googlemap from './modules/Map'
    import toggles from './modules/Toggles'
    import tabl from './modules/Table'
    import fullimage from './modules/FullImage'
    import files from './modules/Files'
    import localFiles from './modules/LocalFiles'
    import ParticipantsTable from './modules/ParticipantsTable'

    export default {
        name: "ModuleBuilder",
        components: {
            text_with_headline:rte,
            image_with_hotspots:hotspots,
            team:team,
            teaser_collection:teaser,
            cards:boxes,
            youtube_video:vid,
            files_collection:files,
            local_files:localFiles,
            participants_list: ParticipantsTable,
            map_with_markers:googlemap,
            toggles,
            tabl,
            simple_image:fullimage
        },
        props: ['schema'],
        data() {
            return {

            };
        },
        methods: {
        }
    };
</script>
