<template>
    <div class="map">
        <div class="google-map" ref="googleMap"></div>
    </div>
</template>
<script>
    import gmapsInit from '../../../services/googlemaps.service';

    export default {
        name: 'googlemap',
        props: ['addresses'],
        data() {
            return {
                google: null,
                map: null,
                geocoder: null,
                infowindow: null,
                bounds: null
            }
        },
        methods:{
            geocodeAddress(location, i) {
                this.geocoder.geocode({ address: location.field_location }, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }

                    this.map.setCenter(results[0].geometry.location);
                    this.createMarker(results[0].geometry.location,location.field_text, i);
                });
            },
            createMarker(latlng,html, i){
                let marker = new window.google.maps.Marker({
                    position: latlng,
                    map: this.map
                });
                let that = this;

                this.google.maps.event.addListener(marker, 'click', (function(marker) {
                    return function() {
                        that.infowindow.setContent(html);
                        that.infowindow.open(this.map, marker);
                    }
                })(marker, i));

                let loc = new window.google.maps.LatLng(marker.position.lat(), marker.position.lng());
                this.bounds.extend(loc);

                this.map.fitBounds(this.bounds);
                this.map.panToBounds(this.bounds);

                if(this.map.getZoom() > 14)
                    this.map.setZoom(14);
            }
        },
        async mounted() {
            try {
                // set options
                let styles = [{"featureType": "administrative", "elementType": "all", "stylers": [{"visibility": "on"}, {"saturation": -100}, {"lightness": 20}]}, {"featureType": "road", "elementType": "all", "stylers": [{"visibility": "on"}, {"saturation": -100}, {"lightness": 40}]}, {"featureType": "water", "elementType": "all", "stylers": [{"visibility": "on"}, {"saturation": -10}, {"lightness": 30}]}, {"featureType": "landscape.man_made", "elementType": "all", "stylers": [{"visibility": "simplified"}, {"saturation": -60}, {"lightness": 10}]}, {"featureType": "landscape.natural", "elementType": "all", "stylers": [{"visibility": "simplified"}, {"saturation": -60}, {"lightness": 60}]}, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}, {"saturation": -100}, {"lightness": 60}]}, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}, {"saturation": -100}, {"lightness": 60}]}];
                let options = {
                    mapTypeControlOptions: {
                        mapTypeIds: [ 'Karte']
                    },
                    zoom: 14,
                    mapTypeId: 'Karte',
                    scrollwheel: false,
                };

                // init global vars
                this.google = await gmapsInit();
                this.map = new window.google.maps.Map(this.$el, options);
                this.geocoder = new window.google.maps.Geocoder();
                this.infowindow = new window.google.maps.InfoWindow();
                this.bounds = new window.google.maps.LatLngBounds();

                // set map styling
                let styledMapType = new window.google.maps.StyledMapType(styles, { name: 'Karte' });
                this.map.mapTypes.set('Karte', styledMapType);


                // geocode addresses and show marker
                for (let i = 0; i < this.addresses.length; i++) {
                    this.geocodeAddress(this.addresses[i], i);
                }

            } catch (error) {
                console.error(error);
            }
        }
    }
</script>
