import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

// views
import AuthView from './views/Authentication';
import Dashboard from './views/Dashboard';
import PageSwitcher from './views/PageSwitcher';
import Events from './views/Events';
import Notifications from './views/Notifications';
import Search from './views/Search';
import Privacy from './views/Datenschutz';

// comps
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ResetPassword from "./components/auth/ResetPassword";
import NewPassword from "./components/auth/NewPassword";
import Navigation from "./components/_globals/Navigation";
import Header from "./components/_globals/Header";


Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            redirect: {
                name: "Login"
            }
        },
        {
            path: '/datenschutz',
            name: 'Datenschutz',
            component: Privacy
        },
        {
            path: '',
            name: 'Authentication',
            component: AuthView,
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                },
                {
                    path: 'reset-password',
                    name: 'Reset Password',
                    component: ResetPassword
                },
                {
                    path: 'new-password',
                    alias: 'password-reset',
                    name: 'New Password',
                    component: NewPassword
                }
            ],
            meta: {
                public: true,
                onlyWhenLoggedOut: true
            }
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            components: {
                default: Dashboard,
                header: Header,
                navigation: Navigation
            },
            props: true
        },
        {
            path: '/events',
            name: 'Events',
            components: {
                default: Events,
                header: Header,
                navigation: Navigation
            },
            props: true
        },
        {
            path: '/meldungen',
            name: 'NotificationOverview',
            components: {
                default: Notifications,
                header: Header,
                navigation: Navigation
            },
            props: true
        },
        {
            path: '/suchergebnisse',
            name: 'SearchOverview',
            components: {
                default: Search,
                header: Header,
                navigation: Navigation
            },
            props: true
        },
        // default 404 redirect
        {
            path: '*',
            name: 'PageSwitcher',
            components: {
                default: PageSwitcher,
                header: Header,
                navigation: Navigation
            },
            props: true
        },
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

router.beforeEach((to, from, next) => {
    store.commit('content/CLEAR_NAVITEMS');
    next();
});

router.afterEach(() => {
    store.commit('config/TOGGLE_NAV', false);
    store.commit('config/CHANGE_NAV_LEVEL', 1);
});

export default router;

