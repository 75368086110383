<template>
    <li :class="[{'is-link':url}, status]">
        <a :href="url" v-if="item.field_link.length">
            <template v-if="item.field_priority === 'yellow'"><svg class="icon"><use xlink:href="#info"></use></svg></template>
            <template v-else-if="item.field_priority === 'red'"><svg class="icon"><use xlink:href="#warn"></use></svg></template>
            <template v-else><svg class="icon"><use xlink:href="#document"></use></svg></template>
            <p>{{ item.field_headline }}</p>
            <svg class="icon arrow"><use xlink:href="#angle-right"></use></svg>
        </a>
        <template v-else>
            <template v-if="item.field_priority === 'yellow'"><svg class="icon"><use xlink:href="#info"></use></svg></template>
            <template v-else-if="item.field_priority === 'red'"><svg class="icon"><use xlink:href="#warn"></use></svg></template>
            <template v-else><svg class="icon"><use xlink:href="#document"></use></svg></template>
            <p>{{ item.field_headline }}</p>
        </template>
    </li>
</template>

<script>

    export default {
        name: 'notification',
        props: ['item','index'],
        data() {
            return {
                url: this.item.field_link[0].alias || this.item.field_link[0].path,
                status: this.item.field_priority
            }
        },
        mounted(){
        }
    };
</script>

