const registerSuccess = (state) => {
    state.registerSuccess = true;
};

const resetSuccess = (state) => {
    state.resetSuccess = true;
};

const newSuccess = (state) => {
    state.newSuccess = true;
};

const authenticated = (state) => {
    state.authenticated = true;
};

const clearError = (state) => {
    state.authProgress = false;
    state.authErrorCode = 0;
    state.authError = "";
};

const authProgress = (state, status) => {
    state.authProgress = status;
};

const authSuccess = (state) => {
    state.authProgress = false;
    state.authError = "";
    state.authErrorCode = 0;
};

const loginError = (state, errorCode) => {
    state.authProgress = false;
    state.authErrorCode = errorCode;

    switch(errorCode) {
        case 401:
            state.authError = "Login fehlgeschlagen. Bitte überprüfe deine Daten.";
            break;
    }
};

const registerError = (state, errorCode) => {
    state.authProgress = false;
    state.authErrorCode = errorCode;

    switch(errorCode) {
        case 401:
            state.authError = "Registrierung fehlgeschlagen. Bitte überprüfe deine Daten.";
            break;
    }
};

const resetError = (state, errorCode) => {
    state.authProgress = false;
    state.authErrorCode = errorCode;

    switch(errorCode) {
        case 401:
            state.authError = "Passwort Zurücksetzen fehlgeschlagen. Bitte überprüfe deine Daten.";
            break;
    }
};

const newError = (state, errorCode) => {
    state.authProgress = false;
    state.authErrorCode = errorCode;

    switch(errorCode) {
        case 401:
            state.authError = "Passwortvergabe fehlgeschlagen. Bitte versuche es erneut.";
            break;
    }
};


export default {
    authenticated,
    registerSuccess,
    resetSuccess,
    newSuccess,
    clearError,
    authProgress,
    authSuccess,
    loginError,
    registerError,
    resetError,
    newError
}
