import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = {
    user:{},
    authProgress: false,
    authErrorCode: 0,
    authError: "",
    registerSuccess: false,
    resetSuccess: false,
    newSuccess: false,
    authenticated: false
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
