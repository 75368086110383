const   navBig = state => state.config.navBig;
const   navOpen = state => state.config.navOpen;
const   navLevel = state => state.config.navLevel;
const   reportProgress = state => state.reportProgress;
const   reportError = state => state.reportError;
const   bugSuccess = state => state.bugSuccess;

export default {
    navBig,
    navOpen,
    navLevel,
    reportProgress,
    reportError,
    bugSuccess
};
