<template>
    <div class="map-wrapper" >
        <h2 v-if="content.field_headline">{{ content.field_headline }}</h2>
        <span v-html="content.field_text"></span>
        <google-map :addresses="content.paragraph" />
    </div>
</template>
<script>
    import GoogleMap from '../partials/GoogleMap';

    export default {
        name: 'gmap',
        props: ['content'],
        components: {
            GoogleMap
        }
    }
</script>
