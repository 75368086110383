import {axiosBasic} from "@/services/axios.service";

const sendReport = async ({ commit }, data) => {

    console.log(commit);
    console.log(data);

    axiosBasic.post("/webapp/bug", data)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log('success');
                commit('SET_BUG_SUCCESS', 'Vielen Dank für Ihre Mitteilung, wir werden diese schnellstmöglich prüfen.')
            }
        })
        .catch(error => {
            console.log('%cError'+'%c – Bug Report failed!', 'color: #d40000', 'color: white');
            commit('SET_BUG_SUCCESS', '')
            commit('newError', error.response.status);
        });
};

export default {
    sendReport,
}
