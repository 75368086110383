<template>
    <li :class="{'is-active':checkActive, 'is-open':open}">
        <!--check for 3rd level-->
        <template v-if="subnav && subnav.length">
            <span class="sublink" @click="openLevel3">
                {{ item.title }} <svg class="icon"><use xlink:href="#angle-right"></use></svg>
            </span>
            <div class="level-3" @mouseleave="open = false">
                <ul>
                    <li class="nav-back" @click="openLevel2"><svg class="icon"><use xlink:href="#angle-left"></use></svg> Zurück</li>
                    <li class="title"><span>{{ item.title }}</span></li>

                    <SubSubNavItem
                        v-for="(item, index) in subnav"
                        :key="'level4'+index"
                        :item="item"
                        :subnav="item.children"
                        @resetNav="resetNav"
                    />
                </ul>
            </div>
        </template>
        <a :href="getLink(item)" v-else-if="item.is_external">{{ item.title }}</a>
        <router-link :to="getLink(item)" v-else @click.native="resetNav">
            {{ item.title }}
        </router-link>
    </li>
</template>

<script>
    import SubSubNavItem from "@/components/_globals/SubSubNavItem";

    export default {
        props:['item', 'name', 'subnav', 'icon'],
        components: {
            SubSubNavItem
        },
        computed:{
            checkActive(){
                const matches = this.checkPathMatch([this.item], this.$route.path);

                // save current item in store
                if(matches) {
                    this.$store.commit('content/SET_NAVITEMTWO', this.item);
                }

                return matches;
            }
        },
        mounted(){
            console.log(this.item.children);
        },
        data() {
            return {
                open:false
            }
        },
        watch: {
            'navLevel'(val){
                if(val !== 3)
                    this.open = false;
            }
        },
        methods: {
            getLink(item){
                if(item.is_external)
                    return item.uri;

                if(item.alias && item.alias !== "/")
                    return item.alias;

                return item.internal_path;
            },
            openLevel3(){
                this.$store.commit('config/CHANGE_NAV_LEVEL', 3);
                this.open = true;
            },
            openLevel2(){
                this.$store.commit('config/CHANGE_NAV_LEVEL', 2);
                this.open = false;
            },
            resetNav(){
                this.open = false;
                this.$store.commit('config/SWITCH_NAV_SIZE', false);
                this.$emit('reset-nav');
            },
            checkPathMatch(items, currentPath) {
                // Base case: check if any item matches the path or if its children do
                return items.some(item => {
                    if (item.alias === currentPath) {
                        return true;
                    }
                    // Check recursively for children
                    if (item.children) {
                        return this.checkPathMatch(item.children, currentPath);
                    }
                    return false;
                });
            },
            mouseLeave(){
                console.log('mouse leave');
                this.deskHover = null
            }
        }
    };
</script>
