const INIT_CONFIG = (state) => {
    let data = JSON.parse(localStorage.getItem('sbss_config')) || null;

    if(data) {
        state.config.navBig = data.navBig;
    }
};

const TOGGLE_NAV = (state, payload) => {
    state.config.navOpen = payload;
};

const SWITCH_NAV_SIZE = (state, payload) => {
    state.config.navBig = payload;

    localStorage.setItem('sbss_config', JSON.stringify(state.config));
};

const CHANGE_NAV_LEVEL = (state, payload) => {
    console.log('%cMutation'+'%c – change nav level!', 'color: mediumpurple', 'color: white');

    state.config.navLevel = payload;
};

const SET_BUG_SUCCESS = (state, payload) => {
    state.bugSuccess = payload;
};


export default {
    INIT_CONFIG,
    TOGGLE_NAV,
    SWITCH_NAV_SIZE,
    CHANGE_NAV_LEVEL,
    SET_BUG_SUCCESS
}
