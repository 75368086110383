<template>
    <div class="input-wrapper" :class="statusClass">
        <input type="password"
               :id="name"
               :name="name"
               @input="inputEvent($event.target.value)"
               :placeholder="placeholder"
                :value="value">

        <svg class="icon icon-invalid"><use xlink:href="#close"></use></svg>
        <svg class="icon icon-valid"><use xlink:href="#check"></use></svg>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>
<script>
    export default {
        name: 'Password',
        props: ['placeholder', 'name', 'value'],
        data(){
            return {
                statusClass: "",
                errorMessage: ""
            }
        },
        methods:{
            resetClass(){
                this.statusClass = "";
            },
            inputEvent(val){
                // validation
                if(val.length < 8) {
                    this.errorMessage = "Dein Passwort muss mindestens 8 Zeichen lang sein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }

                if(val.length > 7) {
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }

                if(!val.length) {
                    this.errorMessage = "";
                    this.statusClass = "";
                    this.$emit('isValid', false);
                }

                // trigger prop change
                this.$emit('input', val);
            },
            validate(val) {
                if(val.length > 7) {
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }else if(val.length && val.length < 8){
                    this.errorMessage = "Dein Passwort muss mindestens 8 Zeichen lang sein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }else{
                    this.errorMessage = "Bitte gib dein Passwort ein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }
            }
        }
    }
</script>
