<template>
    <div class="toggles" >
        <h2>{{ content.headline }}</h2>
        <p>{{ content.text }}</p>
        <toggle-box v-for="(item, index) in content.items" :key="index" :item="item" />
    </div>
</template>
<script>
    import ToggleBox from '../partials/ToggleBox';

    export default {
        name: 'toggles',
        props: ['content'],
        components:{ ToggleBox },
        data() {
            return {}
        }
    }
</script>
