<template>
    <div>
        <h1>{{title}}</h1>
        <module-builder :schema="content"/>
    </div>
</template>

<script>
    import ModuleBuilder from '../components/content/Builder'

    export default {
        name: 'Contentpage',
        props:["content","title"],
        components: {
            ModuleBuilder
        },
        data(){
            return {
            }
        }
    }
</script>
