<template>
    <tr @click="toggleCheckbox">
        <td>
            <span class="check">
                <input type="checkbox" :id="item.id" ref="input" :checked="selected.includes(indexAddition+index)">
                <label :for="item.id"></label>
            </span>
        </td>
        <td class="name">{{ item.name }}</td>
        <td class="filename">{{ item.field_file_name || item.url.split('/')[this.item.url.split('/').length - 1] }}</td>
        <td class="category">{{ item.group }}</td>
        <td class="date">{{ item.date }}</td>
        <td class="filetype">
            <span class="type"><span>{{ getType(item.url) }}</span><svg class="icon"><use xlink:href="#file"></use></svg></span>
        </td>
        <td class="size">{{ formatBytes(item.size) }}</td>
        <td><a :href="item.url" target="_blank"><svg class="icon"><use xlink:href="#download"></use></svg><span>Download</span></a></td>
    </tr>
</template>

<script>
    export default {
        name: 'filerow',
        props: ['item','selected','indexAddition', 'index'],
        methods: {
            formatBytes(bytes, decimals = 1) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            getType(name){
                const re = /(?:\.([^./]+))?$/;
                let newName = re.exec(name)[1];

                if(newName.length > 3)
                    return newName.slice(0, -1);

                return newName;
            },
            toggleCheckbox(){
                this.$refs.input.checked = !this.$refs.input.checked;

                if(this.$refs.input.checked) {
                    this.selected.push(this.index+this.indexAddition);
                }else{
                    let ind = this.selected.indexOf(this.index+this.indexAddition);
                    this.selected.splice(ind, 1);
                }
            }
        }
    };
</script>

