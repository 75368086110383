<template>
    <div class="input-wrapper" :class="statusClass">
        <input type="email"
               :id="name"
               :name="name"
               @input="inputEvent($event.target.value)"
               :placeholder="placeholder">

        <svg class="icon icon-invalid"><use xlink:href="#close"></use></svg>
        <svg class="icon icon-valid"><use xlink:href="#check"></use></svg>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>
<script>
    export default {
        name: 'Email',
        props: ['placeholder', 'name'],
        data(){
            return {
                statusClass: "",
                errorMessage: "",
                regEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            }
        },
        methods:{
            inputEvent(val){
                // validation
                if(val.length && !this.regEx.test(val)) {
                    this.errorMessage = "Bitte gib eine korrekte E-Mail Adresse ein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }

                if(val.length && this.regEx.test(val)) {
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }

                if(!val.length) {
                    this.errorMessage = "";
                    this.statusClass = "";
                    this.$emit('isValid', false);
                }

                // trigger prop change
                this.$emit('input', val);
            },
            validate(val) {
                if(val.length && this.regEx.test(val)) {
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }else if(!val.length){
                    this.errorMessage = "Bitte gib deine E-Mail Adresse ein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }else{
                    this.errorMessage = "Bitte gib eine korrekte E-Mail Adresse ein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }
            }
        }
    }
</script>
