<template>
    <div>
        <h2 class="participants-headline" v-if="content.paragraph">{{content.paragraph[0].field_department}}</h2>

        <table class="participants-table">
            <tr class="firstrow">
                <th colspan="7"><span class="link" @click="getCSV"><svg class="icon"><use xlink:href="#download"></use></svg>CSV Download</span></th>
            </tr>
            <tr>
                <th>Name, Vorname</th>
                <th>Firma</th>
                <th>Funktion</th>
                <th>E-Mail-Adresse</th>
                <th>Telefon</th>
                <th>Mobil</th>
                <th>Postadresse</th>
            </tr>

            <participant-row v-for="(user, index) in participants" :userdata="user" :key="index" :department="(content.paragraph) ? content.paragraph[0].field_department : false"/>
        </table>
    </div>
</template>

<script>
    import ParticipantRow from '../../_partials/ParticipantRow';

    export default {
        name: 'ParticipantsTable',
        props: ['content'],
        components:{
            ParticipantRow
        },
        computed:{
            participants() {
                let users = JSON.parse(JSON.stringify(this.content.field_participants));

                for(let i=0; users.length > i; i++) {
                    users[i].value = JSON.parse(users[i].value);
                }

                return users.sort(function (a, b) {
                    if (a.value.field_company[0] < b.value.field_company[0]) {
                        return -1;
                    }
                    if (a.value.field_company[0] > b.value.field_company[0]) {
                        return 1;
                    }
                    if (a.value.field_lastname < b.value.field_lastname) {
                        return -1;
                    }
                    if (a.value.field_lastname > b.value.field_lastname) {
                        return 1;
                    }
                    return a.value.field_firstname - b.value.field_firstname;
                });
            }
        },
        methods: {
            convertToCSV(objArray) {
                let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray,
                    str = '';

                for (let i = 0; i < array.length; i++) {
                    let line = '';

                    for (let index in array[i]) {
                        if (line !== '')
                            line += ',';

                        line += array[i][index];
                    }

                    str += line + '\r\n';
                }

                return str;
            },
            exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }

                // Convert Object to JSON
                let jsonObject = JSON.stringify(items);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilename = fileTitle + '.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilename);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            },
            getCSV(){
                let headers = {
                    name: 'Name Vorname',
                    company: "Firma",
                    function: "Funktion",
                    email: "E-Mail Adresse",
                    phone: "Telefon",
                    mobile: "Mobil",
                    address: "Postadresse",
                };

                let itemsNotFormatted = JSON.parse(JSON.stringify(this.participants));

                let itemsFormatted = [];


                // format the data
                itemsNotFormatted.forEach((item) => {
                    let name = item.value.field_lastname + " " + item.value.field_firstname,
                        company = item.value.field_company[0],
                        func = item.value.field_department_and_position[0].position,
                        email = item.value.mail,
                        phone = item.value.field_phone_number || "-",
                        mobile = item.value.field_mobile_number || "-",
                        address = item.value.field_address || "-";

                    // assign correct function
                    if(this.content.paragraph[0].field_department) {
                        let dept = item.value.field_department_and_position.filter(usr => usr.department === this.content.paragraph[0].field_department);

                        func = dept[0].position;
                    }

                    itemsFormatted.push({
                        name: name,
                        company: company.replaceAll(',', ' '),
                        function: func.replaceAll(',', ' '),
                        email: email,
                        phone: phone.replaceAll(',', ' '),
                        mobile: mobile.replaceAll(',', ' '),
                        address: address.replaceAll(',', ' '),
                    });
                });

                let fileTitle = (this.content.paragraph[0].field_department) ? this.content.paragraph[0].field_department.replaceAll(' ', ''): 'Projektbeteiligtenliste';

                this.exportCSVFile(headers, itemsFormatted, fileTitle);
            }
        }
    };
</script>

