<template>
    <div class="files">
        <ul>
            <file v-for="(item, index) in content.field_file_section" :item="item" :key="index"/>
        </ul>
    </div>
</template>
<script>
    import File from '../../_partials/LocalFile'

    export default {
        name: 'localfiles',
        props: ['content'],
        components:{
            File
        },
        data(){
            return {
            }
        }
    }
</script>
