<template>
    <div class="image" v-if="content.file">
        <h2 v-if="content.field_headline">{{ content.field_headline }}</h2>
        <span v-html="content.field_text"></span>
        <figure>
            <img :src="content.file[0].url" alt="Bild"/>
            <figcaption v-if="content.file[0].data.title"><p>{{content.file[0].data.title}}</p></figcaption>
        </figure>
    </div>
</template>
<script>
    export default {
        name: 'fullimage',
        props: ['content'],
        data(){
            return {
            }
        }
    }
</script>
