const   getAll = state => state.notifications;
const   getNew = state => state.newNotification;
const   getEvents = state => state.events;
const   getNewEvents = state => state.newEvent;

export default {
    getAll,
    getNew,
    getEvents,
    getNewEvents
};
