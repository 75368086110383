const SET_NAV = (state, data) => {
    console.log('%cMutation'+'%c – save navigation!', 'color: mediumpurple', 'color: white');

    state.navigation = data;
};

const CLEAR_NAVITEMS = (state) => {
    console.log('%cMutation'+'%c – clear both navigation items!', 'color: mediumpurple', 'color: white');

    state.navLevelOne = null;
    state.navLevelTwo = null;
};

const SET_NAVITEMONE = (state, data) => {
    console.log('%cMutation'+'%c – save current active navigation item!', 'color: mediumpurple', 'color: white');

    state.navLevelOne = data;
};

const SET_NAVITEMTWO = (state, data) => {
    console.log('%cMutation'+'%c – save current active navigation item!', 'color: mediumpurple', 'color: white');

    state.navLevelTwo = data;
};

const SET_PAGENAME = (state, data) => {
    console.log('%cMutation'+'%c – save pagename!', 'color: mediumpurple', 'color: white');

    state.pageName = data;
};

const CHANGE_CONTENT = (state, data) => {
    console.log('%cMutation'+'%c – change page content!', 'color: mediumpurple', 'color: white');

    // console.log(data);
    state.currentContent = data;
};

const CHANGE_CONTENT_FILES = (state, data) => {
    console.log('%cMutation'+'%c – change page content for files!', 'color: mediumpurple', 'color: white');

    for(let i=0; data.length > i; i++) {
        if(data[i].url === undefined) {
            data[i].url = data[i].file[0].url;
            data[i].name = data[i].field_file_name;
            data[i].group = data[i].field_file_category;
            data[i].date = data[i].file[0].date;
            data[i].size = data[i].file[0].filesize;
        }
    }

    console.log(data);

    state.currentContent = data;
};

const CHANGE_PARTICIPANTS = (state, data) => {
    console.log('%cMutation'+'%c – change participants!', 'color: mediumpurple', 'color: white');

    console.log(data);

    // state.participants = blocks;
    SWITCH_LOADER(state,false);
};

const CHANGE_TYPE = (state, data) => {
    console.log('%cMutation'+'%c – change page type!', 'color: mediumpurple', 'color: white');

    state.pagetype = data;
};

const SWITCH_LOADER = (state, data) => {
    console.log('%cMutation'+'%c – switch content loader!', 'color: mediumpurple', 'color: white');

    state.loading = data;
};

const SWITCH_404 = (state, data) => {
    console.log('%cMutation'+'%c – switch 404!', 'color: mediumpurple', 'color: white');

    state.show404 = data;
};

const CLEAR_SELECTED = (state) => {
    console.log('%cMutation'+'%c – clear selected files!', 'color: mediumpurple', 'color: white');

    state.selectedFiles = [];
};

const CLEAR_STATE = (state) => {
    console.log('%cMutation'+'%c – clear state on logout!', 'color: mediumpurple', 'color: white');

    state.navigation = [];
    state.pageName = "";
    state.currentContent = null;
    state.loading = false;
    state.show404 = false;
    state.pagetype = "";
    state.selectedFiles  = [];
    state.participants  = [];
};

export default {
    SET_NAV,
    SET_PAGENAME,
    CHANGE_CONTENT,
    CHANGE_CONTENT_FILES,
    CHANGE_PARTICIPANTS,
    CHANGE_TYPE,
    SWITCH_LOADER,
    SWITCH_404,
    CLEAR_SELECTED,
    CLEAR_STATE,
    SET_NAVITEMONE,
    SET_NAVITEMTWO,
    CLEAR_NAVITEMS
};


