<template>
    <section id="auth">
        <img class="logo" src="/assets/images/logos/rz-db-logo.png" alt="Logos – Realisierungszentrum und Deutsche Bahn">
        <span v-lazybg="'/assets/images/bgs/sbss-background.jpg'"></span>
        <div class="auth">
            <router-view/>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Authentication",
        data() {
            return {
            }
        },
        computed: {
        },
        methods: {
        }
    };
</script>
