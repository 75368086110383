<template>
    <section id="privacy">
        <div class="privacy-header">
            <router-link to="/login"><img class="logo" src="/assets/images/logos/rz-db-logo.png" alt="Logos – Realisierungszentrum und Deutsche Bahn"></router-link>
            <router-link to="/login" class="link">Zum Login<svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
        </div>
        <div class="legal">
            <div class="rte">
                <h1>Datenschutzrechtliche Information</h1>
                <strong>gemäß Art. 13 & Art. 14 DSGVO</strong>
                <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen! Die folgenden Informationen geben Ihnen Überblick über die Verarbeitung personenbezogener Daten durch das „Realisierungszentrum 2. S-Bahn-Stammstrecke München“ und die Rechte, die sich aus der europäischen Datenschutz-Grundverordnung (EU-DSGVO) ergeben.</p>
                <h2>Verantwortliche Stelle</h2>
                <strong>IG Realisierungszentrum 2. S-Bahn-Stammstrecke München</strong>
                <p>Arnulfstraße 50<br>
                    80335 München<br>
                    Deutschland / Germany <br><br>
                    +49 160 93118705<br>
                    projekthandbuch@rz2sbss.com</p>
                <h2>Datenschutzbeauftragter</h2>
                <p>Es besteht keine Verpflichtung zur Bestellung eines Datenschutzbeauftragten laut DSGVO Art. 37, da die Kerntätigkeit der Verarbeitung des Verantwortlichen oder des Auftraggebers nicht den in Abs. 1 angeführten Kriterien entspricht.</p>
                <h2>Zu welchen Zwecken & auf welchen Rechtsgrundlagen werden Ihre Daten verarbeitet?</h2>
                <p>Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den Bestimmungen der EU-DSGVO:</p>
                <strong>Verarbeitungen aufgrund einer Einwilligung (Art. 6 Abs. 1 Buchst. a EU-DSGVO)</strong>
                <strong>Verarbeitung freiwilliger Angaben</strong><br>
                <p>Die Angabe Ihrer Kontaktdaten im Rahmen der erstmaligen Registrierung, erfolgt freiwillig und auf Grundlage Ihrer EinwilligungSie können diese Einwilligung jederzeit widerrufen. Ein Widerruf hat zur Folge, dass wir Ihre Daten ab diesem Zeitpunkt zu oben genannten Zwecken nicht mehr verarbeiten. Für einen Widerruf wenden Sie sich bitte an den Verantwortlichen.</p>
                <strong>Verarbeitung aufgrund unseres berechtigten Interesses (Art. 6 Abs. 1 Buchst. f DSGVO)</strong>
                <strong>Zugriffsdaten und Hosting</strong>
                <br>
                <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiertDiese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots.</p>
                <strong>Hosting Dienstleistungen durch einen Drittanbieter</strong>
                <br>
                <p>Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und zur Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen wie folgend beschrieben erhoben werden, werden auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen statt.</p>
                <p>Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.</p>
                <strong>Datenverarbeitung für Zwecke der Verwaltung und Sicherheit des Systems</strong>
                <br>
                <p>Aufgrund der geltenden gesetzlichen Datensicherheitsbestimmungen werden eine Reihe Ihrer Daten für die Verwaltung und Sicherheit des Systems verarbeitet, wie etwa zur Verwaltung von Benutzerkennzeichen, die Zuteilung von Rechten sowie für die Sicherheit des Systems. Dies schließt automationsunterstützt erstellte und archivierte Textdokumente (wie z.B. Korrespondenz) in diesen Angelegenheiten mit ein.</p>
                <strong>Veröffentlichung beruflicher Kontaktdaten im Projektportal</strong>
                <br>
                <p>Zur Kontaktaufnahme durch Lieferanten, Kunden, Partner und weitere Projektbeteiligte, werden berufliche Kontaktdaten, Ihr Name, Zuständigkeiten sowie die Zugehörigkeit des Unternehmens im internen Projektportal veröffentlicht. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer reibungslosen Zusammenarbeit und Kommunikation innerhalb der Projektabwicklung.</p>
                <br><br>
                <strong>Welche Datenkategorien werden verarbeitet?</strong>
                <ul>
                    <li>
                        <strong>Anwenderdaten</strong>
                        <p>mind. Login-Name, Nachname und E-Mail-Adresse, optional Vorname, Kontaktdaten im Unternehmen (Telefon, Mobil, Fax), Abteilungszugehörigkeit, Position im Unternehmen, Dauer der Betriebszugehörigkeit</p>
                    </li>
                    <li>
                        <strong>Berufliche Tätigkeit</strong>
                        <p>Art der von der betroffenen Person genutzten bzw. gelieferten Tätigkeiten, Güter oder Dienstleistungen, Geschäftskontakte, Berufsbezeichnung, u.a.</p>
                    </li>
                    <li>
                        <strong>Kontaktdaten</strong>
                        <p>Telefon, Fax, Mobil, E-Mailadresse</p>
                    </li>
                    <li>
                        <strong>Elektronische Identifikationsdaten</strong>
                        <p>IP-Adressen, Cookies, Verbindungszeiten und -daten, u.a.</p>
                    </li>
                </ul>
                <br><br>
                <strong>Aus welchen Quellen stammen Ihre Daten?</strong>
                <br>
                <p>Personenbezogene Daten werden direkt beim Betroffenen erhoben oder von Geschäftspartnern, Kunden und Lieferanten bereitgestellt.</p>
                <strong>Wie lange werden Ihre Daten gespeichert?</strong>
                <br>
                <p>Die erhobenen Daten werden unverzüglich gelöscht, wenn feststeht, dass sie für die Zwecke, für die sie erhoben wurden, nicht mehr benötigt werden, die betroffene Person ihre Einwilligung, auf die eine Verarbeitung gestützt wurde, widerruft oder personenbezogene Daten unrechtmäßig verarbeitet wurden.</p>
                <strong>An wen werden meine Daten übermittelt?</strong>
                <br>
                <p>Eine Übermittlung findet lediglich durch eine „Offenlegung“ Ihrer personenbezogenen Daten, im Rahmen der Nutzung innerhalb des nicht-öffentlichen Projektportal an Kunden, Lieferanten, Geschäftspartner und andere Projektbeteiligte statt.</p>
                <strong>Werden Daten auch an internationale Organisationen oder an ein Drittland übermittelt?</strong>
                <br>
                <p>Es findet keine Übermittlung personenbezogener Daten an ein Drittland (Staaten außerhalb der Europäischen Union bzw. dem Europäischen Wirtschaftsraum) oder eine internationale Organisation statt.</p>
                <strong>Automatische Entscheidungsfindung inkl. Profiling gemäß Art.22 EU-DSGVO</strong>
                <br>
                <p>Wir setzen kein Verfahren zur automatisierten Entscheidungsfindung einschließlich Profiling ein, die Ihnen gegenüber eine rechtliche Wirkung haben oder Sie in ähnlicher Weise erheblich beeinträchtigen.</p>
                <strong>Ihre Rechte</strong>
                <br>
                <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu:</p>
                <ul>
                    <li>gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;</li>
                    <li>gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
                    <li>gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
                        <br>
                        <ul>
                            <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                            <li>zur Erfüllung einer rechtlichen Verpflichtung;</li>
                            <li>aus Gründen des öffentlichen Interesses oder</li>
                            <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
                        </ul>
                        erforderlich ist;
                    </li>
                    <li>gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
                        <br>
                        <ul>
                            <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li>
                            <li>die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;</li>
                            <li>wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder</li>
                            <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                        </ul>
                    </li>
                    <li>gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
                    <li>gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li>
                    <li><strong>Widerspruchsrecht</strong><br>Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu. Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.</li>
                </ul>
                <p>Zur Ausübung Ihrer Rechte wenden Sie sich an uns. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Bayern ist das „Bayrische Landesamt für Datenschutzaufsicht (BayLDA)“ zuständig.</p>
                <p>
                    Kontakt: <br>
                    Bayerisches Landesamt für Datenschutzaufsicht (BayLDA)<br>
                    Promenade 18<br>
                    91522 Ansbach<br>
                    Deutschland<br><br>
                    Telefon: +49 (0) 981 180093-0<br>
                    Telefax: +49 (0) 981 180093-800<br>
                    Website: https://www.lda.bayern.de
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Datenschutz",
        data() {
            return {
            }
        },
        computed: {
        },
        methods: {
        }
    };
</script>
