<template>
    <section class="view search-view">
        <div class="search-full">
            <input type="text" placeholder="Suchbegriff eingeben..." v-model="searchVal" @input="debounceSearch" ref="search">
            <p v-if="!loading && (results.length || noResults)" class="count">{{ '(' + results.length + ' Treffer)'}}</p>
            <div class="spinner" v-if="loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        </div>
        <div class="search-overview">
            <h1 v-if="!loading && (results.length || noResults)">{{ (results.length > 1) ? results.length + ' Ergebnisse' : results.length + ' Ergebnis' }}</h1>
            <ul>
                <search-result v-for="(item, index) in results" :key="index" :item="item" :crop-length="120"/>
            </ul>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import SearchResult from '../components/search/Result'
    import {debounce} from "debounce";

    export default {
        name: 'Search',
        computed:{
            ...mapGetters({
                navigation: 'content/getNav',
                results: 'search/getResults',
                loading: 'search/getLoading',
                noResults: 'search/getNoResults',
                searchTerm: 'search/getSearchTerm',
            }),
        },
        components: {
            SearchResult
        },
        methods: {
            debounceSearch: debounce(function () {
                if(this.searchVal) {
                    this.$store.dispatch('search/performSearch', this.searchVal);
                    this.$store.commit('search/UPDATE_TERM', this.searchVal);
                }else{
                    this.$store.commit('search/SWITCH_NORESULT', false);
                    this.$store.commit('search/SAVE_RESULTS', []);
                }
            }, 500)
        },
        data(){
            return {
                searchVal: ""
            }
        },
        mounted(){
            if(!this.navigation.length)
                this.$store.dispatch('content/initData');

            if (this.searchTerm.length) {
                this.searchVal = this.searchTerm
            }
        },
    }
</script>
