import Vue from 'vue'
import Vuex from 'vuex'
import loadingModule from './modules/loading'
import authModule from './modules/auth'
import configModule from './modules/config'
import contentModule from './modules/content'
import notificationsModule from './modules/notifications'
import searchModule from './modules/search'
import filesModule from './modules/files'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    modules: {
        loading: loadingModule,
        auth: authModule,
        config: configModule,
        content: contentModule,
        notifications: notificationsModule,
        search: searchModule,
        files: filesModule,
    }
});

export default store

