import actions from './actions';
import getters from './getters';
import mutations from './mutations';


// initial state
const state = {
    info: null,
    results: [],
    searchTerm: "",
    loading: false,
    noResults: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
