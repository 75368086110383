<template>
    <div class="video" >
        <h2>{{ content.field_headline }}</h2>
        <span v-html="content.field_text"></span>
        <div class="wrapper">
            <iframe width="16" height="9" :src="'https://www.youtube-nocookie.com/embed/'+content.field_youtube_video_code+'?&rel=0&showinfo=0&hd=1'" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'vid',
        props: ['content']
    }
</script>
