import getters from './getters';
import mutations from './mutations';
import actions from "./actions";

// initial state
const state = {
    config: {
        navBig: false,
        navOpen: false,
        navLevel: 1,
    },
    reportProgress: false,
    reportError: "",
    bugSuccess: "",
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
