<template>
    <div class="table" >

    </div>
</template>
<script>
    export default {
        name: 'tabl',
        props: ['content'],
        data() {
            return {}
        }
    }
</script>
