const getResults = state => state.results;
const getInfo = state => state.info;
const getNoResults = state => state.noResults;
const getLoading = state => state.loading;
const getSearchTerm = state => state.searchTerm;

export default {
    getResults,
    getInfo,
    getNoResults,
    getLoading,
    getSearchTerm
};
