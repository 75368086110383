import axios from 'axios';
import {RedirectService} from "../../../services/redirect.service";

const getZIP = (payload) => {
    console.log('%cAction'+'%c – get files ZIP!', 'color: #bada55', 'color: white');

    const requestData = {
        method: 'POST',
        url: "/webapp/files/collection",
        headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/json',
            'X-Requested-With':'webapp'
        },
        data: payload,
        responseType: 'blob'
    };

    axios(requestData)
        .then(response => {
           const url = window.URL.createObjectURL(new Blob([response.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', 'Archiv.zip');
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};


export default {
    getZIP
}
