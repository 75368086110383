<template>
    <li>
        <div><a :href="item.file[0].url" target="_blank" class="name">{{ item.field_file_name }}</a></div>
        <div><span class="filename">{{ item.file[0].filename }}</span></div>
        <div><span class="category" v-if="item.field_file_category">{{ item.field_file_category }}</span></div>
        <div><span class="date">{{ item.file[0].date }}</span></div>
        <div>
            <span class="fileicon">
                <span class="type"><span>{{ getType(item.file[0].filename) }}</span><svg class="icon"><use xlink:href="#file"></use></svg></span>
                <span class="size">{{ formatBytes(item.file[0].filesize) }}</span>
            </span>
        </div>
        <div><a :href="item.file[0].url" class="btn" target="_blank"><svg class="icon"><use xlink:href="#download"></use></svg>Download</a></div>
    </li>
</template>

<script>
    export default {
        name: 'file',
        props: ['item'],
        methods: {
            formatBytes(bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            getType(name){
                const re = /(?:\.([^./]+))?$/;
                let newName = re.exec(name)[1];

                if(newName.length > 3)
                    return newName.slice(0, -1);

                return newName;
            }
        }
    };
</script>

