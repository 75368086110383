<template>
    <li class="is-event">
        <a :href="item.field_link[0].uri" target="_blank" v-if="item.field_link[0].external">
            <time>{{ formatDate(item.field_start_date) }}</time>
            <p>{{ item.field_headline }}</p>
            <svg class="icon arrow"><use xlink:href="#angle-right"></use></svg>
        </a>
        <router-link :to="item.field_link[0].uri" v-else>
            <time>{{ formatDate(item.field_start_date) }}</time>
            <p>{{ item.field_headline }}</p>
            <svg class="icon arrow"><use xlink:href="#angle-right"></use></svg>
        </router-link>
    </li>
</template>

<script>
    export default {
        name: 'event',
        props: ['item','index'],
        methods:{
            formatDate(date){
                let event = new Date(date),
                    options = { month: 'short', day: 'numeric' },
                    day = event.toLocaleDateString('de-DE', options);

                day = day.split('.')[0] + " " + day.split('.')[1].slice(0,4);

                return day;
            }
        }
    };
</script>

