import actions from './actions';
import getters from './getters';
import mutations from './mutations';


// initial state
const state = {
    notifications: [],
    newNotification: false,
    events: [],
    newEvent: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
