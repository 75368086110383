<template>
    <section class="view events">
        <div class="events-overview">
            <h1>Alle Events</h1>
            <ul>
                <event v-for="(item, index) in filteredEvents" :key="index" :item="item"/>
            </ul>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from "vuex";
    import Event from '../components/notifications/Event'

    export default {
        name: 'Events',
        computed:{
            ...mapGetters({
                navigation: 'content/getNav',
                events: 'notifications/getEvents',
            }),
            filteredEvents: function () {
                return this.events.filter(function (item) {
                    return item.field_start_date
                })
            }
        },
        components: {
            Event
        },
        data(){
            return {
            }
        },
        mounted(){
            if(!this.navigation.length)
                this.$store.dispatch('content/initData');
        }
    }
</script>
