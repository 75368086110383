import axios from 'axios';
import { RedirectService } from '../../../services/redirect.service';

const initAll = ({ commit }) => {
    console.log('%cAction'+'%c – get notifications!', 'color: #bada55', 'color: white');

    const requestData = {
        method: 'GET',
        url: "/notifications?_format=webapp_json&flat",
        headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'multipart/form-data',
            'X-Requested-With':'webapp'
        },
    };

    axios(requestData)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log(response);

                commit('INIT_ALL', response.data.paragraph);
            }
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};

const initEvents = ({ commit }) => {
    console.log('%cAction'+'%c – get events!', 'color: #bada55', 'color: white');

    const requestData = {
        method: 'GET',
        url: "/events?_format=webapp_json&flat",
        headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'multipart/form-data',
            'X-Requested-With':'webapp'
        },
    };

    axios(requestData)
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log(response);

                commit('INIT_EVENTS', response.data.paragraph);
            }
        })
        .catch(error => {
            if(error.response.status === 401 && !RedirectService.redirecting) {
                RedirectService.login()
            }
        });
};

export default {
    initAll,
    initEvents
}
