<template>
    <p class="error" v-if="message.length">{{ message }}</p>
</template>
<script>
    export default {
        name: 'Error',
        props: ['message'],
        data(){
            return {

            }
        },
        methods:{

        },
    }
</script>
