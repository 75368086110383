<template>
    <div class="team">
        <h2 v-if="content.field_headline">{{ content.field_headline }}</h2>
        <table v-if="content.field_show_as_list">
            <tr v-for="(item, index) in content.user" :key="index">
                <th>{{ item.field_firstname + " " + item.field_lastname }}</th>
                <td v-if="position(item)">{{ position(item) }}</td>
                <td v-else>-</td>
                <td v-if="item.field_phone_number" class="phone">Tel. <a :href="'tel:'+item.field_phone_number">{{item.field_phone_number}}</a></td>
                <td v-else>-</td>
                <td v-if="item.field_mobile_number" class="phone">Mob. <a :href="'tel:'+item.field_mobile_number">{{item.field_mobile_number}}</a></td>
                <td v-else>-</td>
                <td><a :href="'mailto:'+item.mail">{{item.mail}}</a></td>
            </tr>
        </table>
        <ul v-else>
            <li v-for="(item, index) in content.user" :key="index">
                <img :src="(item.file && item.file.length)? item.file[0].url : '/assets/images/content/placeholder.jpg'" :alt="item.name">
                <div class="person">
                    <strong>{{ item.field_firstname + " " + item.field_lastname }}</strong>
                    <p v-if="position(item)">{{ position(item) }}</p>
                </div>
                <div class="contact">
                    <p v-if="item.field_phone_number">Tel. {{item.field_phone_number}}</p>
                    <p v-if="item.field_mobile_number">Mob. {{item.field_mobile_number}}</p>
                    <a :href="'mailto:'+item.mail">{{item.mail}}</a>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'team',
        props: ['content'],
        data(){
            return {
                position(item){
                    return (item.paragraph) ? item.paragraph.filter(pos => pos.parent_field_name === "field_department_and_position")[0].field_position : false;
                }
            }
        }
    }
</script>
