<template>
    <li>
        <div><a :href="item.value" target="_blank" class="name">{{name}}</a></div>
        <div><span class="filename">{{ item.value }}</span></div>
<!--        <div><span class="category" v-if="item.field_file_category">{{ item.field_file_category }}</span></div>-->
        <div><span class="date">{{ date }}</span></div>
        <div>
            <span class="fileicon">
                <span class="type"><span>{{ getType(item.value) }}</span><svg class="icon"><use xlink:href="#file"></use></svg></span>
<!--                <span class="size">{{ formatBytes(item.file[0].filesize) }}</span>-->
            </span>
        </div>
        <div><a :href="item.value" class="btn" target="_blank"><svg class="icon"><use xlink:href="#download"></use></svg>Download</a></div>
    </li>
</template>

<script>
    export default {
        name: 'localfile',
        props: ['item'],
        computed: {
            fileArr(){
                let fileArr = this.item.value.split('/');

                return fileArr[fileArr.length - 1].split('_');
            },
            name(){
                return this.fileArr[5];
            },
            date(){
                let date = this.fileArr[0];

                return date.substring(4,6) + "." + date.substring(2, 4) + ".20" + date.substring(0, 2);
            }
        },
        methods: {
            formatBytes(bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            getType(name){
                const re = /(?:\.([^./]+))?$/;
                let newName = re.exec(name)[1];

                if(newName.length > 3)
                    return newName.slice(0, -1);

                return newName;
            }
        }
    };
</script>

