<template>
    <form id="reset" :class="{'is-invalid':showErrors}" novalidate @submit="checkForm">
        <span class="shape"></span>
        <div class="form-inner success-message" v-if="resetSuccess">
            <h1><b>Vielen Dank</b></h1>
            <p>Sie erhalten in Kürze eine E-Mail mit dem Link zum Zurücksetzen Ihres Passworts!</p>
            <div class="row">
                <div class="col50">
                    <router-link to="/login" class="link-switch log-in">Zum Login <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
                </div>
            </div>
        </div>
        <div class="row row-single" v-if="!resetSuccess">
            <h1><b>Passwort vergessen?</b></h1>
            <p>Geben Sie Ihren Nutzernamen ein, um Ihr Passwort zurückzusetzen.</p>
            <text-input ref="username" empty="Bitte geben Sie Ihren Nutzernamen ein!" v-model="username" placeholder="Nutzername" name="username" :required="true" @isValid="changeUsernameValidation"/>
            <button class="btn" type="submit">Passwort zurücksetzen</button>
            <router-link to="/login" class="link-switch log-in">Zum Login <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
            <div class="spinner" v-if="authProgress"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters } from "vuex";
    import TextInput from './fields/Input'

    export default {
        name: "ResetPassword",
        data() {
            return {
                username: "",
                usernameValid: false,
                showErrors:false
            };
        },
        components: {TextInput},
        computed: {
            ...mapGetters({
                resetSuccess: 'auth/resetSuccess',
                authProgress: 'auth/authProgress'
            })
        },
        methods: {
            changeUsernameValidation(value){
                this.usernameValid  = value;

                this.updateErrorClass();
            },
            updateErrorClass(){
                if(this.usernameValid)
                    this.showErrors = false;
            },
            checkForm(e) {
                this.$refs.username.validate(this.username);

                if(this.usernameValid) {
                    this.performPasswordChange();
                }else{
                    this.showErrors = true;
                }

                e.preventDefault();
            },
            performPasswordChange() {
                let payload = {
                    username: this.username
                };

                this.$store.dispatch('auth/prePasswordReset', payload);
                this.password = "";
            }
        }
    };
</script>
