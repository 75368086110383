<template>
    <div class="file-overview">
        <h1>{{title || 'Filemanager'}}</h1>
        <div class="functions">
            <label for="search" class="search"><input id="search" ref="search" placeholder="Suche" @input="search" v-model="searchFilter"/></label>
            <span class="btn-filter" v-if="filter.length" :class="{'is-open':filterOpen}">
                <span @click="filterOpen = !filterOpen"><svg class="icon"><use xlink:href="#filter"></use></svg>Filter</span>
                <span class="filter">
                    <strong>Filter <svg class="icon close" @click="filterOpen = false"><use xlink:href="#close"></use></svg></strong>
                    <ul>
                        <li v-for="(item, index) in filter" :item="item" :index="index" :key="index">
                            <input type="checkbox" :id="item" :checked="activeFilters.includes(item)" @change="changeActiveFilter" :value="item">
                            <label :for="item">{{item.toUpperCase()}}</label>
                        </li>
                    </ul>
                    <span class="link" @click="resetFilters">Filter zurücksetzen</span>
                </span>
            </span>
            <span class="btn" :class="{'is-disabled':selectedFiles.length <= 1}" @click="downloadSelected" v-if="filteredContent.length > 2">Download</span>
        </div>
        <table class="file-table">
            <tr>
                <th><span class="check"><input type="checkbox" id="all" @change="selectAll" :checked="selectedFiles.length === 10"><label for="all"></label></span></th>
                <th class="name" @click="changeSorting('name')" :class="{'is-active':sortingCol === 'name', 'is-reversed': sortingCol === 'name' && !sortASC}">Bezeichnung <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th class="filename" @click="changeSorting('filename')" :class="{'is-active':sortingCol === 'filename', 'is-reversed': sortingCol === 'filename' && !sortASC}">Dateiname <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th class="category" @click="changeSorting('category')" :class="{'is-active':sortingCol === 'category', 'is-reversed': sortingCol === 'category' && !sortASC}">Kategorie <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th class="date" @click="changeSorting('date')" :class="{'is-active':sortingCol === 'date', 'is-reversed': sortingCol === 'date' && !sortASC}">Datum <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th class="filetype" @click="changeSorting('filetype')" :class="{'is-active':sortingCol === 'filetype', 'is-reversed': sortingCol === 'filetype' && !sortASC}">Typ <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th class="size" @click="changeSorting('size')" :class="{'is-active':sortingCol === 'size', 'is-reversed': sortingCol === 'size' && !sortASC}">Größe <svg class="icon"><use xlink:href="#angle-down"></use></svg></th>
                <th></th>
            </tr>
            <file v-for="(item, index) in paginatedData" :item="item" :key="index" :index="index" :indexAddition="pageNumber * 10" :selected="selectedFiles"/>
        </table>

        <div class="functions">
            <ul class="pagination" v-show="filteredContent.length > 10">
                <li class="page-item page-nav"><span class="page-link" rel="prev" :disabled="pageNumber === 0" @click="prevPage"><svg class="icon"><use xlink:href="#angle-left"></use></svg></span></li>
                <li class="page-item" :class="{ active: pageNumber === 0 }"><span class="page-link" @click="jumpPage(0)">1</span></li>

                <li v-if="pageNumber > 2" class="dots">...</li>
                <li class="page-item" v-if="pageNumber > 1"><span class="page-link" @click="jumpPage(pageNumber-1)">{{ pageNumber }}</span></li>
                <li class="page-item active" v-if="pageNumber !== 0 && pageNumber !== pageCount-1">
                    <span class="page-link" :title="pageNumber+1">{{ pageNumber+1 }}</span>
                </li>
                <li class="page-item" v-if="pageNumber < pageCount-2"><span class="page-link" @click="jumpPage(pageNumber+1)">{{ pageNumber+2 }}</span></li>
                <li v-if="pageNumber < pageCount-2" class="dots">...</li>

                <li class="page-item" :class="{ active: pageNumber === pageCount-1 }"><span class="page-link" @click="jumpPage(pageCount-1)">{{ pageCount }}</span></li>
                <li class="page-item page-nav"><span class="page-link" rel="next" :disabled="pageNumber >= pageCount -1" @click="nextPage"><svg class="icon"><use xlink:href="#angle-right"></use></svg></span></li>
            </ul>
            <span class="btn" :class="{'is-disabled':selectedFiles.length <= 1}" @click="downloadSelected" v-if="filteredContent.length > 2">Download</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import File from '../components/_partials/FileRow'

    export default {
        name: 'Filepage',
        props:['content', 'title'],
        data(){
            return {
                pageNumber: 0,
                selectVal: false,
                searchFilter: "",
                sortingCol: "date",
                sortASC: true,
                filteredContent: [],
                filter:[],
                activeFilters:[],
                filterOpen: false
            }
        },
        components:{
            File
        },
        computed:{
            ...mapGetters({
                selectedFiles: 'files/getSelectedFiles'
            }),
            pageCount(){
                let l = this.filteredContent.length;
                return Math.ceil(l/10);
            },
            paginatedData(){
                let start = this.pageNumber * 10,
                    end = start + 10,
                    tempContent = this.content;

                // filter by checkboxes
                if(this.activeFilters.length)
                    tempContent = tempContent.filter(file => !this.activeFilters.length || this.activeFilters.includes(this.getType(file)));

                // filter by search
                let filter = this.searchFilter.toLowerCase();
                if(filter)
                    tempContent = tempContent.filter(file => file.name.toLowerCase().includes(filter) || file.url.toLowerCase().includes(filter));


                // save content after filtering
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.filteredContent = tempContent;

                // default sorting
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.filteredContent.sort(this.compare);

                // show current pages results
                return this.filteredContent.slice(start, end);
            }
        },
        methods:{
            resetFilters(){
                this.filterOpen = false;
                this.activeFilters = [];
            },
            setFilters(){
                for(let i=0; this.content.length > i; i++) {
                    let singleFilter = this.getType(this.content[i]);
                    if(!this.filter.includes(singleFilter))
                        this.filter.push(singleFilter);
                }
            },
            changeActiveFilter(e){
                if(e.target.checked) {
                    this.activeFilters.push(e.target.value.toLowerCase());
                }else{
                    this.activeFilters = this.activeFilters.filter(item => item !== e.target.value.toLowerCase());
                }

                this.pageNumber = 0;
            },
            changeSorting(column){
                if(column === this.sortingCol)
                    this.sortASC = !this.sortASC;
                else {
                    this.sortingCol = column;
                    this.sortASC= true;
                }
            },
            compare(a, b) {
                let valA, valB;
                let fileA = a.url.split('/');
                let fileB = b.url.split('/');
                let ate = a.date.toLowerCase().split('.'),
                    bate = b.date.toLowerCase().split('.');

                switch(this.sortingCol) {
                    case 'name':
                        valA = a.name.toLowerCase();
                        valB = b.name.toLowerCase();
                        break;
                    case 'filename':
                        valA = fileA[fileA.length -1].toLowerCase();
                        valB = fileB[fileB.length -1].toLowerCase();
                        break;
                    case 'date':
                        valA = bate[2] + "." + bate[1] + "." + bate[0];
                        valB = ate[2] + "." + ate[1] + "." + ate[0];
                        break;
                    case 'filetype':
                        valA = this.getType(a).toLowerCase();
                        valB = this.getType(b).toLowerCase();
                        break;
                    case 'size':
                        valA = a.size.toLowerCase();
                        valB = b.size.toLowerCase();
                        break;
                    case 'category':
                        valA = (a.group) ? a.group.toLowerCase() : "";
                        valB = (b.group) ? b.group.toLowerCase() : "";
                        break;
                }

                // change sorting direction
                if(this.sortASC) {
                    if(this.sortingCol === "size"){
                        // use int sorting
                        return parseInt(a.size) - parseInt(b.size);
                    }else{
                        if (valA > valB) {
                            return 1;
                        } else if (valA < valB) {
                            return -1;
                        }
                    }
                }else{
                    if(this.sortingCol === "size"){
                        // use int sorting
                        return parseInt(b.size) - parseInt(a.size);
                    }else{
                        if (valA < valB) {
                            return 1;
                        } else if (valA > valB) {
                            return -1;
                        }
                    }
                }
            },
            getType(obj){
                const re = /(?:\.([^./]+))?$/;

                return re.exec(obj.url)[1];
            },
            jumpPage(ind) {
                this.selectVal = false;
                this.$store.commit('files/CLEAR_SELECTED');
                this.pageNumber = ind;
            },
            nextPage(){
                this.selectVal = false;
                this.$store.commit('files/CLEAR_SELECTED');

                if(this.pageNumber < this.pageCount-1)
                    this.pageNumber++;
            },
            prevPage(){
                this.selectVal = false;
                this.$store.commit('files/CLEAR_SELECTED');

                if(this.pageNumber > 0)
                    this.pageNumber--;
            },
            downloadSelected(){
                if(this.selectedFiles.length > 1){
                    let payload = [];

                    for(let i=0; this.selectedFiles.length > i; i++) {
                        console.log(this.selectedFiles[i]);
                        payload.push(this.filteredContent[this.selectedFiles[i]].id)
                    }

                    this.$store.dispatch('files/getZIP', payload)

                }
            },
            selectAll(e) {
                this.$store.commit('files/CLEAR_SELECTED');

                if(e.target.checked) {
                    for(let i=0; this.paginatedData.length > i; i++) {
                        this.selectedFiles.push(i+(this.pageNumber*10));
                    }
                }
            },
            search(){
                this.pageNumber = 0;
                this.$store.commit('files/CLEAR_SELECTED');
            }
        },
        mounted(){
            this.filteredContent = this.content;
            this.setFilters();
        },
        watch: {
            'content': function() {
                this.filteredContent = this.content;
            },
        }
    }
</script>
