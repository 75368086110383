<template>
    <transition name="modal-primary">
        <div v-if="showModal" class="modal" @click.self="closeModal">
            <div class="inner">
                <div class="modal-header">
                    <h1><slot name="header"></slot></h1>
                    <svg class="icon close" @click="closeModal"><use xlink:href="#close"></use></svg>
                </div>
                <div class="modal-content">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'modal',
        props: ['showModal'],
        methods: {
            closeModal ()
            {
                this.$emit('close')
            }
        }
    };
</script>

