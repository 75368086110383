<template>
    <tr>
        <td>{{ user.field_lastname + ", " + user.field_firstname }}</td>
        <td v-if="user.field_company[0]">{{user.field_company[0]}}</td>
        <td v-else>-</td>
        <td>{{userFunction}}</td>
        <td><a :href="'mailto:'+user.mail">{{user.mail}}</a></td>
        <td v-if="user.field_phone_number" class="phone"><a :href="'tel:'+user.field_phone_number">{{user.field_phone_number}}</a></td>
        <td v-else>-</td>
        <td v-if="user.field_mobile_number" class="mobile"><a :href="'tel:'+user.field_mobile_number">{{user.field_mobile_number}}</a></td>
        <td v-else>-</td>
        <td v-if="user.field_address">{{user.field_address}}</td>
        <td v-else>-</td>
    </tr>
</template>

<script>
    export default {
        name: 'ParticipantRow',
        props: ['userdata', 'department'],
        computed: {
            user(){
                return this.userdata.value;
            },
            // eslint-disable-next-line vue/return-in-computed-property
            userFunction(){
                if(this.department) {
                    let dept = this.user.field_department_and_position.filter(item => item.department === this.department);

                    return dept[0].position;
                }else if(this.user.field_department_and_position.length){
                    return this.user.field_department_and_position[0].position;
                }
            }
        }
    };
</script>

