<template>
    <li class="search" :class="{open:showSearch}">
        <span class="btn-search" @click="showWindow"><svg class="icon"><use xlink:href="#search"></use></svg></span>

        <ul class="search-window" v-if="showSearch" v-click-outside="onClickOutside">
            <li :class="{'is-active': !nav}">
                <input type="text" placeholder="Suchbegriff eingeben..." v-model="searchVal" @input="debounceSearch" ref="search">
                <p v-if="!loading && (results.length || noResults)" class="count">{{ '(' + results.length + ' Treffer)'}}</p>
                <div class="spinner" v-if="loading"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                <ul>
                    <template v-if="results.length" >
                        <result v-for="(item, index) in results.slice(0,5)" :key="index" :item="item" :crop-length="100" @item-clicked="closeWindow"/>
                    </template>
                    <li class="empty" v-if="noResults">Deine Suchanfrage ergab leider keine Treffer!</li>
                    <li class="all" @click="closeWindow" v-if="results.length"><router-link to="/suchergebnisse" >Alle Suchergebnisse <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link></li>
                </ul>
            </li>
        </ul>
    </li>
</template>

<script>
    import { mapGetters } from 'vuex';
    import vClickOutside from 'v-click-outside';
    import { debounce } from "debounce";

    import Result from './Result';

    export default {
        directives: {
            clickOutside: vClickOutside.directive
        },
        computed: {
            ...mapGetters({
                results: 'search/getResults',
                loading: 'search/getLoading',
                noResults: 'search/getNoResults',
                searchTerm: 'search/getSearchTerm',
            }),
        },

        data() {
            return {
                showSearch: false,
                nav: 0,
                searchVal: ""
            }
        },
        components: {
            Result
        },
        methods: {
            onClickOutside () {
                if(!window.getSelection().toString().length)
                    this.closeWindow();
            },
            showWindow(){
                this.showSearch = true;

                this.$nextTick(() => {
                    this.$refs.search.focus();
                })
            },
            closeWindow(){
                console.log('close');
                this.showSearch = false;
            },
            debounceSearch: debounce(function () {
                if(this.searchVal) {
                    this.$store.dispatch('search/performSearch', this.searchVal);
                    this.$store.commit('search/UPDATE_TERM', this.searchVal);
                }else{
                    this.$store.commit('search/SWITCH_NORESULT', false);
                    this.$store.commit('search/SAVE_RESULTS', []);
                }
            }, 500)
        },
        mounted(){
            if (this.searchTerm.length) {
                this.searchVal = this.searchTerm
            }
        }
    };
</script>
