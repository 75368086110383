<template>
    <li class="is-link">
        <router-link :to="item.path" v-if="item.path" @click.native="closeWindow">
            <strong>{{ item.title || item.headline || item.label }}</strong>
            <p v-html="item.text.substring(0,cropLength)+'...'"></p>
            <svg class="icon arrow"><use xlink:href="#angle-right"></use></svg>
        </router-link>
    </li>
</template>
<script>
    export default {
        name: 'Result',
        props: ['item','index', 'cropLength'],
        data() {
            return {}
        },
        methods: {
            closeWindow(){
                this.$emit('item-clicked');
            }
        }
    };
</script>
