const   authCode = state => state.authErrorCode;
const   authError = state => state.authError;
const   authProgress = state => state.authProgress;
const   registerSuccess = state => state.registerSuccess;
const   resetSuccess = state => state.resetSuccess;
const   newSuccess = state => state.newSuccess;
const   authenticated = state => state.authenticated;

export default {
    registerSuccess,
    authProgress,
    authCode,
    authError,
    resetSuccess,
    newSuccess,
    authenticated
};
