<template>
    <div class="input-wrapper" :class="statusClass">
        <label class="checkbox" :for="name">
            <input type="checkbox" :id="name" :name="name" v-model="checkVal" @change="inputEvent()"/>
            <span v-html="content"></span>
        </label>
    </div>
</template>
<script>
    export default {
        name: 'CheckList',
        props: ['content', 'name', 'required'],
        data(){
            return {
                statusClass: "",
                errorMessage: "",
                checkVal: ""
            }
        },
        methods:{
            inputEvent(){
                // validation
                this.errorMessage = "";
                this.statusClass = "";

                if(!this.checkVal) {
                    this.statusClass = "is-invalid";
                }

                if(this.checkVal) {
                    this.statusClass = "is-valid";
                }

                // trigger prop change
                this.$emit('input', this.checkVal)
            },
            validate() {
                if(this.checkVal){
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }else{
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }
            }
        },
    }
</script>
