import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = {
    navigation: [],
    navLevelOne: null,
    navLevelTwo: null,
    pageName: "",
    currentContent: null,
    loading: false,
    show404: false,
    pagetype: "",
    selectedFiles : [],
    participants : [],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
