<template>
    <div class="teaser" :data-count="(count) ? count : 1">
        <div v-for="(item, index) in content.paragraph" :key="index" :class="{'is-reversed': count === 1 && !childs[0].field_image_left}">
            <img :src="item.file[0].url" :alt="item.field_headline">
            <div class="content">
                <h2 v-if="item.field_headline">{{ item.field_headline }}</h2>
                <span v-html="item.field_text"></span>

                <template v-if="item.field_link && item.field_link[0]">
                    <a :href="item.field_link[0].path" v-if="isFile(item.field_link[0].path)" class="btn"><svg class="icon"><use xlink:href="#download"></use></svg> Download</a>
                    <a :href="item.field_link[0].path" v-else class="link">Mehr erfahren <svg class="icon"><use xlink:href="#angle-right"></use></svg></a>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'teaser',
        props: ['content'],
        data(){
            return {
                count: this.content.paragraph.length,
                childs: this.content.paragraph
            }
        },
        methods: {
            isFile(file){
                let regex = /\.(pdf|doc|xls|mp3|mp4|zip|tar)$/i;

                return regex.test(file);
            }
        }
    }
</script>
