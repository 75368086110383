<template>
    <form id="login" @submit="checkForm" novalidate :class="{'is-invalid':showErrors, 'has-failed':authError.length}">
        <span class="shape"></span>
        <div class="row row-single">
            <h1><b>Willkommen</b> im Digitalen Projekthandbuch</h1>
            <error :message="authError"/>
            <text-input ref="username" empty="Bitte geben Sie Ihren Nutzernamen ein!" v-model="username" placeholder="Nutzername" name="username" :required="true" @isValid="changeUsernameValidation"/>
            <password ref="password" v-model="password" placeholder="Passwort" name="password" @isValid="changePasswordValidation"/>
            <button class="btn" type="submit">Einloggen</button>
            <router-link to="/reset-password" class="link link-small">Passwort vergessen?</router-link>
            <router-link to="/register" class="link-switch sign-up">Zur Registrierung <svg class="icon"><use xlink:href="#angle-right"></use></svg></router-link>
            <div class="spinner" v-if="authProgress"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        </div>
    </form>
</template>

<script>
    import { mapGetters } from "vuex";
    import Password from './fields/Password'
    import TextInput from './fields/Input'
    import Error from './fields/Error'

    export default {
        name: "Login",
        components: { Password, TextInput, Error},
        data() {
            return {
                username: "",
                usernameValid: false,
                password: "",
                passwordValid: false,
                showErrors:false
            };
        },
        computed: {
            ...mapGetters({
                authProgress: 'auth/authProgress',
                authError: 'auth/authError'
            })
        },
        methods: {
            changeUsernameValidation(value){
                this.usernameValid  = value;

                this.updateErrorClass();
            },
            changePasswordValidation(value){
                this.passwordValid  = value;

                this.updateErrorClass()
            },
            updateErrorClass(){
                if(this.passwordValid && this.usernameValid)
                    this.showErrors = false;
            },
            checkForm(e) {
                this.$refs.username.validate(this.username);
                this.$refs.password.validate(this.password);

                if(this.usernameValid && this.passwordValid) {
                    this.performLogin();
                }else{
                    this.showErrors = true;
                }

                e.preventDefault();
            },
            performLogin() {
                let payload = {
                    name: this.username,
                    password: this.password
                };

                this.password = "";
                this.$refs.password.resetClass();
                this.$store.dispatch('auth/preLogin', payload);
            }
        },
        mounted(){
            // test if user is logged in and can request data
            this.$store.dispatch('content/testData');
        }
    };
</script>
