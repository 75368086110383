<template>
    <div class="boxes">
        <h2>{{ content.field_headline }}</h2>
        <span v-html="content.field_text"></span>

        <div class="box-wrapper">
            <a :href="(item.field_link) ? item.field_link[0].alias : ''" v-for="(item, index) in content.paragraph" :key="index">
                <img v-if="item.file" :src="item.file[0].url" :alt="item.title">
                <h3 v-if="item.field_headline">{{ item.field_headline }}</h3>
                <span v-html="item.field_text"></span>
                <span class="link">Mehr erfahren <svg class="icon"><use xlink:href="#angle-right"></use></svg></span>
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'boxes',
        props: ['content'],
        data(){
            return {
            }
        }
    }
</script>
