import axios from 'axios';
import router from "../../../router";

const performSearch = ({ commit }, data) => {
    console.log('%cAction'+'%c – get search results!', 'color: #bada55', 'color: white');

    console.log(data);

    commit('SWITCH_LOADER', true);

    const requestData = {
        method: 'GET',
        url: "/webapp/search-content?search_keys=" + encodeURI(data) + '&limit=500&offset=0',
        headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'multipart/form-data',
            'X-Requested-With':'webapp'
        },
    };

    axios(requestData)
        .then(response => {
            console.log(response);
            if(response.status === 200 && response.data.results.length) {
                commit('SWITCH_NORESULT', false);
                commit('SAVE_RESULTS', response.data.results);
                commit('SAVE_INFO', response.data.info);
            }else{
                commit('SWITCH_NORESULT', true);
                commit('SAVE_RESULTS', []);
            }

            commit('SWITCH_LOADER', false);
        })
        .catch(error => {
            if(error.response.status === 401)
                router.push('/login');
        });
};

export default {
    performSearch
}
