<template>
    <div class="input-wrapper" :class="statusClass">
        <input type="text"
               :id="name"
               :name="name"
               @input="inputEvent($event.target.value)"
               :placeholder="placeholder"
               :maxlength="max">

        <svg class="icon icon-invalid"><use xlink:href="#close"></use></svg>
        <svg class="icon icon-valid"><use xlink:href="#check"></use></svg>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>
<script>
    export default {
        name: 'TextInput',
        props: ['placeholder', 'name', 'required', 'max', 'empty'],
        data(){
            return {
                statusClass: "",
                errorMessage: ""
            }
        },
        methods:{
            inputEvent(val){
                // validation
                this.errorMessage = "";
                this.statusClass = "";

                if(!val.length && this.required) {
                    this.errorMessage = (this.empty)? this.empty : "Bitte trage etwas ein!";
                    this.statusClass = "is-invalid";
                }

                if(val.length) {
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                }

                // trigger prop change
                this.$emit('input', val);
            },
            validate(val) {
                if(val !== undefined && val.length){
                    this.errorMessage = "";
                    this.statusClass = "is-valid";
                    this.$emit('isValid', true);
                }else if((val === undefined || !val.length) && this.required) {
                    this.errorMessage = (this.empty)? this.empty : "Bitte trage etwas ein!";
                    this.statusClass = "is-invalid";
                    this.$emit('isValid', false);
                }
            }
        },
    }
</script>
