const SAVE_RESULTS = (state, data) => {
    console.log('%cMutation'+'%c – save search results to state!', 'color: mediumpurple', 'color: white');

    state.results = data;
};

const SWITCH_NORESULT = (state, data) => {
    console.log('%cMutation'+'%c – switch noresults indicator!', 'color: mediumpurple', 'color: white');

    state.noResults = data;
};

const SAVE_INFO = (state, data) => {
    console.log('%cMutation'+'%c – save search result info to state!', 'color: mediumpurple', 'color: white');

    state.info = data;
};

const SWITCH_LOADER = (state, data) => {
    console.log('%cMutation'+'%c – switch search loader!', 'color: mediumpurple', 'color: white');

    state.loading = data;
};

const UPDATE_TERM = (state, data) => {
    console.log('%cMutation'+'%c – update search term!', 'color: mediumpurple', 'color: white');

    state.searchTerm = data;
};


export default {
    SAVE_RESULTS,
    SWITCH_NORESULT,
    SAVE_INFO,
    SWITCH_LOADER,
    UPDATE_TERM
}
