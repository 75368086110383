<template>
    <div class="toggle-box" :class="{'is-open':isOpen}">
        <div class="toggle-headline" @click="toggle">{{ headline }}</div>
        <div class="toggle-content" :style="getHeight">
            <div v-html="content" ref="content" class="rte" :class="{'is-2col':columns}"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ToggleBox',
        props: ['content', 'headline', 'open', 'columns'],
        data() {
            return {
                isOpen: false,
                toggleHeight: (this.$refs.content) ? this.$refs.content.clientHeight : 0,
            }
        },
        computed:{
            getHeight(){
                return (this.isOpen) ? "height:" + this.toggleHeight + "px;" : "height:0px;";
            }
        },
        methods:{
            toggle(){
                this.isOpen = !this.isOpen;
                this.toggleHeight = this.$refs.content.clientHeight;
            }
        },
        mounted(){
            let that = this;

            that.isOpen = this.open;

            setTimeout(function(){
                if(that.open) {
                    that.toggleHeight = that.$refs.content.clientHeight;
                }
            }, 200);
        }
    }
</script>
