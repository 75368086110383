<template>
    <li class="bug-report">
        <svg class="icon" @click="openModal"><use xlink:href="#bug"></use></svg>

        <modal :showModal="modal" @close="closeModal">
            <template slot="header">
                Fehler melden
            </template>
            <template slot="content">
                <form class="report-window" @submit="checkForm" novalidate :class="{'is-invalid':showErrors, 'has-failed':reportError.length}" v-if="!bugSuccess.length">
                    <label for="reportmessage">Fehlerbeschreibung*</label>
                    <textarea cols="3" id="reportmessage" ref="reportmessage" name="reportmessage" placeholder="Bitte beschreiben Sie, was auf dieser Seite nicht stimmt." v-model="reportmessage"></textarea>
                    <p class="error" v-if="fieldError.length">{{ fieldError }}</p>
                    <div class="row">
                        <span class="btn btn-grey btn-outline" @click="closeModal">Zurück</span>
                        <button class="btn" type="submit">Absenden</button>
                    </div>
                    <div class="spinner" v-if="reportProgress"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                </form>
                <p v-if="bugSuccess.length" class="report-success">{{bugSuccess}}</p>
            </template>
        </modal>
    </li>
</template>

<script>
import Modal from '@/components/_partials/Modal'
import {mapGetters} from "vuex";

export default {
    components: { Modal},
    data() {
        return {
            reportmessage: "",
            modal: false,
            showErrors:false,
            fieldError:''
        }
    },
    computed: {
        ...mapGetters({
            reportProgress: 'config/reportProgress',
            reportError: 'config/reportError',
            bugSuccess: 'config/bugSuccess'
        })
    },
    methods: {
        openModal(){
            this.modal = true;
        },
        closeModal(){
            this.modal = false;
        },
        sendReport(){
            const payload = {
                msg: this.reportmessage,
                referer: navigator.userAgent
            }
            this.$store.dispatch('config/sendReport', payload);
        },
        checkForm(e) {
            if(this.$refs.reportmessage.value.length) {
                this.sendReport();
            }else{
                this.showErrors = true;
                this.fieldError = 'Bitte beschreibe dein Problem.'
            }

            e.preventDefault();
        }
    }
};
</script>
